exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-airport-transfers-tsx": () => import("./../../../src/pages/services/airport-transfers.tsx" /* webpackChunkName: "component---src-pages-services-airport-transfers-tsx" */),
  "component---src-pages-services-island-tours-tsx": () => import("./../../../src/pages/services/island-tours.tsx" /* webpackChunkName: "component---src-pages-services-island-tours-tsx" */),
  "component---src-pages-services-taxi-service-tsx": () => import("./../../../src/pages/services/taxi-service.tsx" /* webpackChunkName: "component---src-pages-services-taxi-service-tsx" */)
}

